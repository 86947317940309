import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Accounts} from "../../../services/accounts.service";
import {SharedService} from "../../../services/shared.service";
import {Router} from "@angular/router";
import {EmployeeProfileService} from "../../profile-page/employee-profile/employee-profile.service";
import {UploadFileService} from "../../../services/upload-file.service";
import {MatChipSelectionChange} from "@angular/material/chips";
import {Settings} from "../../../globals/settings.model";
import {BackpackerProfile} from "../../../interfaces/backpacker-profile";


@Component({
  selector: 'app-profile-basic',
  templateUrl: './profile-documents.component.html',
  styleUrls: ['./profile-documents.component.scss']
})
export class ProfileDocumentsComponent implements OnInit {
  employmentDetails: UntypedFormGroup;
  profileData: BackpackerProfile;
  accountTier: string = 'standard';
  fullName: string = '';
  resumeFileUploaded: boolean = false;
  isTinyMCELoaded: boolean = false;
  resumeFile: File | null = null;

  public tinymceConfig = {
    height: 400,
    menubar: '',
    plugins: 'lists wordcount',
    placeholder: "Write a cover letter, this will be sent to employers when you apply for a job.",
    toolbar: 'undo redo | bullist numlist | italic underline ',
    apiKey: '04uw29s5ctlefbzx93isgcm8iyjp6pkwkhxxr2mmt6v6bitl',
    branding: false
  };

  public tinymceConfig2 = {
    height: 300,
    menubar: '',
    plugins: 'lists wordcount',
    placeholder: "Enter work experience that may be relevant to backpacker jobs you are applying for.",
    toolbar: 'undo redo | bullist numlist | italic underline ',
    apiKey: '04uw29s5ctlefbzx93isgcm8iyjp6pkwkhxxr2mmt6v6bitl',
    branding: false
  };

  files: File[] = [];
  resumeFiles: File[] = [];


  constructor(private uploadService: UploadFileService, private formBuilder: UntypedFormBuilder, private apiService: Accounts, private sharedService: SharedService, private router: Router, private profileService: EmployeeProfileService, public globals: Settings) {
  }

  ngOnInit() {
    this.employmentDetails = this.formBuilder.group({
      resume_link: [''],
      cover_letter: [''],
      work_experience: [''],
      work_fields: [[]]
    });

    this.getUserProfileData();
  }

  async update() {
    const oldResumeLink = this.employmentDetails.value.resume_link;

    const newResumeLink = await this.uploadResume();
    this.employmentDetails.patchValue({resume_link: newResumeLink});

    if (newResumeLink && oldResumeLink) {
      try {
        const response = await this.uploadService.deleteOverwrittenFile(oldResumeLink).toPromise();
      } catch (error) {
        console.error("An error occurred during deletion:", error);
        // Handle error appropriately
      }
    }
    this.apiService.updateDocumentsBackpackerProfile(this.employmentDetails.value).subscribe((res) => {
      this.router.navigate(['/profile']);
    });
  }

  close() {
    this.router.navigate(['/profile']);
  }


  onSelectResume(event) {
    this.resumeFile = event.addedFiles[0]; // Only accept the first file

  }

  onRemoveResume(event) {
    this.resumeFile = null;
  }

  async uploadResume(): Promise<string> {
    let profileResumeURL = "";
    if (!this.resumeFile) {
      return Promise.resolve("");
    }

    let filePath = 'resumes/' + Math.random() * 10000000000 + "_" + this.resumeFile.name;

    try {
      const response = await this.uploadService.uploadFile(this.resumeFile, filePath).toPromise();
      if (response.status == "success" && response['fileURL']) {
        profileResumeURL = response['fileURL'];
      }
    } catch (error) {
      console.error("An error occurred during upload:", error);
      // Handle error appropriately
    }

    return profileResumeURL;
  }

  getUserProfileData() {
    this.profileService.currentProfile.subscribe(profile => {
      if (profile) {
        this.profileData = profile;
        this.accountTier = profile.account_tier
        this.fullName = profile.full_name
        this.employmentDetails.patchValue({
          resume_link: profile.resume_link,
          cover_letter: profile.cover_letter,
          work_experience: profile.work_experience,
          work_fields: profile.work_fields
        });
      }
    });
  }

  workFieldSelectionChange(workFieldArray: any) {
    this.employmentDetails.get('work_fields').setValue(workFieldArray);
  }


}
