import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Accounts} from "../../services/accounts.service";
import {SharedService} from "../../services/shared.service";
import {UploadFileService} from "../../services/upload-file.service";
import {NavigationEnd, Router} from "@angular/router";
import {DatePipe, PlatformLocation} from '@angular/common';
import {Settings} from "../../globals/settings.model";
import {MatChipSelectionChange} from "@angular/material/chips";
import {Stripe} from "@stripe/stripe-js";
import {filter} from "rxjs";
import {environment} from "../../../environments/environment.prod";

declare var Stripe: any;

@Component({
  selector: 'app-job-post',
  templateUrl: './employee-create-profile-signup.component.html',
  styleUrls: ['./employee-create-profile-signup.component.scss', '../latest-jobs/list-jobs.component.scss', '../login-page/login-page.component.scss', '../search-backpackers/backpacker-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeCreateProfileSignup implements OnInit {
  private stripe: Stripe;
  accountTier: string = 'certified';
  buttonTexts: { [key: string]: string } = {};
  generatedReference: string;
  generatedURLSlug: string;
  isTinyMCELoaded: boolean = false;
  resumeFileUploaded: boolean = false;
  errorMsg: string;
  isAnywhereSelected: boolean = false;
  coverLetterEmpty: boolean = false;
  workLocationString: string;
  collectedData = false;
  hasResume: boolean = false;
  hasExperience: boolean = false;

  public tinymceConfig = {
    height: 400,
    menubar: '',
    plugins: 'lists wordcount',
    placeholder: "Enter a bit about yourself or cover letter here. When you apply for jobs, you can change this to be more specific for each individual job.",
    toolbar: 'undo redo | bullist numlist | italic underline ',
    apiKey: '04uw29s5ctlefbzx93isgcm8iyjp6pkwkhxxr2mmt6v6bitl',
    branding: false,
    elementpath: false
  };

  public tinymceConfig2 = {
    height: 350,
    menubar: '',
    plugins: 'lists wordcount',
    placeholder: "Enter work experience that may be relevant to backpacker jobs you are applying for.",
    toolbar: 'undo redo | bullist numlist | italic underline ',
    apiKey: '04uw29s5ctlefbzx93isgcm8iyjp6pkwkhxxr2mmt6v6bitl',
    elementpath: false,
    branding: false
  };

  profilePictureFile: File | null = null;
  resumeFile: File | null = null;

  profileInfo: UntypedFormGroup;
  employmentDetails: UntypedFormGroup;
  advertiseYourself: UntypedFormGroup;
  profilePayload: any = {};

  workerTypes = ["Backpacker", "Student", "Seasonal Worker", "Resident", "Other"];
  states = ["Australian Capital Territory", "New South Wales", "Northern Territory", "Queensland", "South Australia", "Tasmania", "Victoria", "Western Australia"];

  countries = [
    {name: 'Australia', code: 'AU'},
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Åland Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Cote D\'Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
    {name: 'Korea, Republic of', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People\'S Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia and Montenegro', code: 'CS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
  ];
  filteredCountries = this.countries;

  salaryTypeSelected = "Per Hour";


  constructor(
    private uploadService: UploadFileService,
    private formBuilder: UntypedFormBuilder,
    private apiService: Accounts,
    public sharedService: SharedService,
    private AWSService: UploadFileService,
    private route: Router,
    public globals: Settings,
    private datePipe: DatePipe,
    private platformLocation: PlatformLocation) {


  }

  ngOnInit(): void {

    // This will check for back button pressed if stripe is called
    this.route.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkProfileCreated();
      });

    // Initial check in case the component is loaded fresh and not from navigation
    this.checkProfileCreated();

    this.stripe = Stripe(environment.stripeProdKey);

    this.generatedReference = this.sharedService.generateRandomString();
    this.profileInfo = this.formBuilder.group({
      jobCategory: ['', Validators.required],
      workDate: ['', Validators.required],
      contactNumber: ['', Validators.required],
      linkedinProfile: [''],
      websiteProfile: [''],
      nationality: ['', Validators.required],
      workLocation: ['', Validators.required],
      workFields: [[]]
    });

    this.employmentDetails = this.formBuilder.group({
      resume: [''],
      coverLetter: ['', Validators.required],
      workExperience: [''],
    });

    this.computeButtonTexts();


  }

  private checkProfileCreated(): void {
    this.apiService.checkProfileCreated().subscribe(
      (profileCreated) => {
        if (profileCreated.profileFound) {
          this.route.navigate(['/profile']);
        }
      },
      error => {
        console.error('Error checking profile creation:', error);
        // Handle error appropriately
      }
    );
  }

  onSelect(event) {
    this.profilePictureFile = event.addedFiles[0]; // Only accept the first file
  }

  onSelectResume(event) {
    this.resumeFile = event.addedFiles[0]; // Only accept the first file
  }

  onRemoveProfilePicture(event) {
    this.profilePictureFile = null;
  }

  onRemoveResume(event) {
    this.resumeFile = null;
  }

  async uploadProfilePicture(): Promise<string> {
    let profileImageURL = "";

    if (!this.profilePictureFile) {
      return Promise.resolve("");
    }

    let filePath = 'profile-pictures/backpackers/' + Math.random() * 10000000000 + "_" + this.profilePictureFile.name;

    try {
      const response = await this.uploadService.uploadFile(this.profilePictureFile, filePath).toPromise();
      if (response.status === "success" && response['fileURL']) {
        profileImageURL = response['fileURL'];
      }
    } catch (error) {
      console.error("An error occurred during upload:", error);
      // Handle error appropriately
    }

    return profileImageURL;
  }


  async uploadResume(): Promise<string> {
    let profileResumeURL = "";
    if (!this.resumeFile) {
      return Promise.resolve("");
    }

    let filePath = 'resumes/' + Math.random() * 10000000000 + "_" + this.resumeFile.name;

    try {
      const response = await this.uploadService.uploadFile(this.resumeFile, filePath).toPromise();
      if (response.status == "success" && response['fileURL']) {
        profileResumeURL = response['fileURL'];
      }
    } catch (error) {
      console.error("An error occurred during upload:", error);
      // Handle error appropriately
    }

    return profileResumeURL;
  }


  async createProfile() {
    try {
      this.profilePayload = Object.assign({}, this.profileInfo.value, this.employmentDetails.value);

      // upload and add the files
      const uploadedImageUrl = await this.uploadProfilePicture();
      const uploadedResumeUrl = await this.uploadResume();

      this.profilePayload.profilePicture = uploadedImageUrl;
      this.profilePayload.resume = uploadedResumeUrl;

      const date = this.profileInfo.controls['workDate'].value;
      this.profilePayload['workDate'] = this.datePipe.transform(date, 'yyyy-MM-dd');

      // Convert selected states to a comma-separated string
      const selectedStates = this.profileInfo.controls['workLocation'].value;
      if (selectedStates) {
        this.profilePayload['workLocation'] = selectedStates.join(',');
      }

      this.profilePayload['accountTier'] = this.accountTier;
      if (this.accountTier != 'standard') {

        const selectedWorkFields = this.profileInfo.controls['workFields'].value;
        if (selectedWorkFields) {
          this.profilePayload['workFields'] = selectedWorkFields.join(',');
        }
      } else {
        this.profilePayload['workFields'] = "";
      }

      // finish this api call as it should save profile instead of job
      this.apiService.saveBackpackerProfile(this.profilePayload).subscribe(
        response => {
          if (this.accountTier != 'standard') {
            this.apiService.backpackerProfileCheckout(this.accountTier).subscribe((session: any) => {
                this.stripe.redirectToCheckout({
                  sessionId: session.sessionId
                }).then((result) => {
                  // Handle result.error here if the redirect fails
                });
              }
            );
          } else {
            this.route.navigate(['/profile']);
          }
        },
        (error) => {
          this.errorMsg = error.error.returnMsg;
        }
      );
    } catch (error) {
      this.errorMsg = "Image upload failed";
    }
  }

  skipCreateProfile() {
    this.route.navigate(['/']);
  }

  stateChanges(event: any) {
    const selectedValues = this.profileInfo.get('workLocation').value;
    if (selectedValues.includes('Anywhere In Australia')) {
      this.isAnywhereSelected = true;
      this.profileInfo.get('workLocation').setValue(['Anywhere In Australia'], {emitEvent: false});
    } else {
      this.isAnywhereSelected = false;
    }
  }


  selectTier(tierSelection) {
    this.accountTier = tierSelection;
    this.computeButtonTexts();
  }

  computeButtonTexts() {
    this.buttonTexts['standard'] = this.getButtonTextTier('standard');
    this.buttonTexts['certified'] = this.getButtonTextTier('certified');
    this.buttonTexts['pro'] = this.getButtonTextTier('pro');
  }


  getButtonTextTier(tier: string): string {
    if (tier === 'standard' || tier === 'pro') {
      if (this.accountTier === tier) {
        this.profileInfo.get('workFields').setValue([]);
        return 'Selected';
      }
      return 'Select';
    } else {
      if (this.accountTier === tier) {
        return 'Free 30 Day Trial Selected';
      }
      return 'Try Free For 30 Days'
    }
  }

  workFieldSelectionChange(workFieldArray: any) {
    this.profileInfo.get('workFields').setValue(workFieldArray);
  }


  finalStepCalled() {
    this.workLocationString = this.profileInfo.get('workLocation').value.join(', ');
    this.coverLetterEmpty = this.employmentDetails.get('coverLetter').value == '';
    this.hasResume = this.employmentDetails.get('resume').value;
    this.hasExperience = this.employmentDetails.get('workExperience').value;
    this.collectedData = true;
  }

}
