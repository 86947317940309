<h2 class="text-center !text-2xl !mt-3" mat-dialog-title>{{data.jobTitle}} Application</h2>
<div mat-dialog-content>
  <div class="profile-section">
    <div class="flex flex-col items-center mb-4">
      <img *ngIf="backpackerProfile?.profile_picture != ''" alt="Profile Picture" class="profile-pic" src="{{backpackerProfile?.profile_picture}}">
      <img *ngIf="backpackerProfile?.profile_picture == ''" alt="profile picture" class="profile-pic" src="assets/profile-picture-placeholder.jpg">
      <div class="profile-details">
        <p class="profile-name font-medium mb-1 text-lg">{{backpackerProfile?.full_name}}</p>
        <a *ngIf="backpackerProfile?.has_resume" class="underline profile-resume"
           href="{{backpackerProfile?.resume_link}}" target="_blank">View Resume</a>
      </div>
    </div>
    <div class="profile-details w-full">
      <div class="flex flex-row items-center flex-wrap justify-center">
        <p class="profile-worker-type">{{backpackerProfile?.job_category}}</p>
        <mat-icon class="dot-spacer">fiber_manual_record</mat-icon>
        <p class="profile-nationality">{{backpackerProfile?.nationality}}</p>
      </div>
    </div>

    <form [formGroup]="jobApplicationForm" class="apply-form">
      <mat-form-field appearance="outline">
        <mat-label>Available From Date</mat-label>
        <input formControlName="availDate" matInput placeholder="Select date" type="date">
      </mat-form-field>

      <h3 class="mt-3">Write/Edit Cover Letter</h3>
      <p>Updating your cover letter here will not affect your general profile cover letter.</p>
      <editor [init]="tinymceConfig" apiKey="04uw29s5ctlefbzx93isgcm8iyjp6pkwkhxxr2mmt6v6bitl"
              formControlName="coverLetter"></editor>


      <h3 class="mt-12 mb-2">Do you have any experience for this job?</h3>
      <p class="mt-1">Experience must be specific to this job/field. Your general work experience is available on your public profile for members only.</p>
      <mat-radio-group class="flex flex-col mt-3" formControlName="isExperienced">
        <mat-radio-button [value]="true">Yes, I have experience in this field</mat-radio-button>
        <mat-radio-button [value]="false">No, I do not have specific experience in this field of work</mat-radio-button>
      </mat-radio-group>



      <ng-container *ngIf="jobApplicationForm.get('isExperienced').value == true">
        <h3 class="mt-12">Talk about your experience</h3>
        <p class="mt-2 mb-4">Employers will be notified that you have experience in this field</p>

        <editor [init]="experienceEditorConfig" apiKey="04uw29s5ctlefbzx93isgcm8iyjp6pkwkhxxr2mmt6v6bitl"
                formControlName="workExperience"></editor>
      </ng-container>

    </form>
  </div>

  <div class="!justify-center" mat-dialog-actions>
    <button [mat-dialog-close]="false" class="mr-auto" mat-button>Cancel</button>
    <button (click)="onApplyClick()" class="apply-button" color="primary" mat-raised-button>Apply Now</button>
  </div>
</div>
